import { Dispatch } from 'redux';
import http from '../../../service/axios-custom';
import { API_URL, ERROR_TIME } from '../../../constants';
import { saveRefreshTokenToCookie } from '../../../service';
import { RegActions } from './models';
import { RegActionsTypes } from './types';

export const sendCode = (phone: string) => async (dispatch:Dispatch<RegActions>) => {
  try {
    const res = await http.post(`${API_URL}/authorization/send_code`, { phone });
    dispatch({ type: RegActionsTypes.REG_SUCCESS, payload: res.data.data });
  } catch (error: any) {
    dispatch({ type: RegActionsTypes.REG_FAILED, payload: error.response.data.message });
    setTimeout(() => dispatch({ type: RegActionsTypes.REG_FAILED, payload: '' }), ERROR_TIME);
  }
};

export const login = (data: { phone: string, code: string }) => async (dispatch: Dispatch<RegActions>) => {
  try {
    const res = await http.post(`${API_URL}/authorization/login`, data);
    saveRefreshTokenToCookie(res.data.data.refresh_token);
    dispatch({ type: RegActionsTypes.LOGIN_SUCCESS, payload: res.data.data });
    window.location.href = '/chats';
  } catch (error: any) {
    dispatch({ type: RegActionsTypes.LOGIN_FAILED, payload: error.response.data.message });
    setTimeout(() => dispatch({ type: RegActionsTypes.LOGIN_FAILED, payload: '' }), ERROR_TIME);
  }
};
