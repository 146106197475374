import { IStringIndex, IErrors } from '../types';

interface IProps {
  phone?: string,
  code?: string
}

const descriptor: IStringIndex = {
  phone: {
    validate: (value: string) => {
      if (!value?.length) return 'Введите номер телефона';
      if (!/^\+?[78][-\\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/.test(`+7${value}`)) {
        return 'Неверный формат номера';
      }
      return false;
    },
  },
  code: {
    validate: (value: string) => {
      if (!value?.length) return 'Введите код';
      return false;
    },
  },
};

export default (obj: IProps): { isSuccess: boolean, errors: IErrors[] | null } => {
  const errors: any = [];
  Object.entries(obj).forEach(([key, value]) => {
    const res: string = descriptor[key]?.validate(value);
    res && errors.push({ cause: key, message: res });
  });
  return {
    isSuccess: !errors.length,
    errors: errors.length ? errors : null,
  };
};
