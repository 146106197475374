import { io } from 'socket.io-client';
import { SOCKET_URL } from '../constants';
import { _isAuthenticated } from './index';

export default io(SOCKET_URL, {
  extraHeaders: {
    Authorization: `Bearer ${_isAuthenticated()}`,
  },
  forceNew: true,
});
