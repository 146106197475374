import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './root-reducer';

export const history = createBrowserHistory();
export const store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(thunk)));

export default store;
