import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Avatar from 'react-avatar';
import { Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTypedSelector } from '../../store/hooks';
import SvgIcon from '../svg';
import { DOMAIN } from '../../constants';
import Utils from '../../service/utils';
import { _getSelfId } from '../../service';
import Menu from './menu';

import './header.less';

function Header() {
  const { id } = useParams();
  const selfId = _getSelfId();
  const { chat, chats } = useTypedSelector((state) => state.chats);
  const {
    chatUsers, avatar, name, chatType,
  } = chat || {};

  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (chatType === 'private' && chatUsers?.length) {
      const user = chatUsers?.find((user) => user.id !== selfId);
      setUser(user);
    }
  }, [chatUsers]);

  return (
    <div className="header">
      <div className="header__logo">
        <SvgIcon name="logo" />
      </div>
      {id && (
      <div className="header__chat-info">
        {chat && (
        <div className="header__chat-info_left">
          <Avatar
            size="54"
            name={name || ''}
            src={avatar ? DOMAIN + avatar : ''}
            round
          />
          <div className="caption">
            <p className="caption_title">
              {name}
            </p>
            <p className="caption_subtitle">
              {chatType === 'private'
                ? user?.is_online ? 'Онлайн' : user?.last_active
                  ? `Был в сети ${moment(user?.last_active).format('HH:mm')}` : ''
                : ` ${chatUsers?.length} ${Utils.getEnding(chatUsers?.length, 'Участник', ['a', 'ов'])}`}
            </p>
          </div>
        </div>
        )}
        <div className="header__chat-info_right">
          <div className="menu-icon">
            <Popover content={Menu} showArrow={false} placement="bottomLeft">
              <MoreOutlined />
            </Popover>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

export default Header;
