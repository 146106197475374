import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from './index';
import * as ProfileActions from '../pages/profile/duck/actions';
import * as ChatActions from '../pages/chats/duck/actions';
import * as UsersActions from '../pages/contacts/duck/actions';
import * as RegActions from '../pages/registration/duck/actions';

export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const actions = {
  ...UsersActions,
  ...ProfileActions,
  ...ChatActions,
  ...RegActions,
};

export const useActions = () => bindActionCreators(
  actions,
  useDispatch(),
);
