import { Dispatch } from 'redux';
import http from '../../../service/axios-custom';
import { API_URL } from '../../../constants';
import { ContactsActions } from './models';
import { ContactsActionsTypes } from './types';

export const getUsers = (page = 1, limit = 10) => async (dispatch: Dispatch<ContactsActions>) => {
  try {
    const res = await http.get(`${API_URL}/users?page=${page}&limit=${limit}`);
    dispatch({ type: ContactsActionsTypes.GET_USERS_SUCCESS, payload: res.data.data });
  } catch (error: any) {
    dispatch({ type: ContactsActionsTypes.GET_USERS_SUCCESS, payload: error.response.data.message });
  }
};
