import { RegActionsTypes } from './types';
import { RegActions, IState } from './models';

const initialState: IState = {
  code_success: false,
  access_token: null,
  error: null,
};

export function regReducer(state = initialState, action: RegActions): IState {
  switch (action.type) {
    case RegActionsTypes.REG_SUCCESS:
      return {
        ...state,
        code_success: true,
        error: null,
      };
    case RegActionsTypes.REG_FAILED:
      return {
        ...state,
        code_success: false,
        error: action.payload,
      };
    case RegActionsTypes.LOGIN_SUCCESS:
      return {
        ...state,
        code_success: false,
        access_token: action.payload?.access_token,
        error: null,
      };
    case RegActionsTypes.LOGIN_FAILED:
      return {
        ...state,
        code_success: false,
        access_token: null,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default regReducer;
