import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface IPageTransitions {
  children: React.ReactNode,
}

function PageTransitions({ children }: IPageTransitions) {
  return (
    <AnimatePresence>
      <motion.div
        style={{ width: '100%', height: '100%' }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, type: 'tween' }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export default PageTransitions;
