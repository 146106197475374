import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import ImgCrop from 'antd-img-crop';
import moment from 'moment';
import {
  Form, Button, Input, Upload, DatePicker,
} from 'antd';
import Presence from '../../components/animations/presence';
import SvgIcon from '../../components/svg';
import { getProfile, updateProfile } from './duck/actions';
import { useTypedSelector, useActions } from '../../store/hooks';
import { DOMAIN } from '../../constants';
import { IStringIndex, UserData } from '../../types';
import PageTransitions from '../../components/animations/pageTransitions';
import { signOut } from '../../service';

import './profile.less';

function Index() {
  const { profile, error }: IStringIndex = useTypedSelector((state) => state.profile);
  const {
    name, nickname, avatar, birth: birthDay,
  }: UserData = profile || {};

  const { updateProfile, getProfile, filesUpload } = useActions();

  const [isVisibleSaveBtn, setIsVisibleSaveBtn] = useState<boolean>(false);
  const [confirmExit, setConfirmExit] = useState<boolean>(false);
  const [birth, setBirth] = useState<string | null>('');

  const [form] = Form.useForm();

  const onValuesChange = (values: any) => {
    const [key, value] = Object.entries(values)[0];
    profile[key] !== value ? setIsVisibleSaveBtn(true) : setIsVisibleSaveBtn(false);
  };

  useEffect(() => {
    if (!profile) getProfile();
  }, []);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({ name, nickname });
      setBirth(birth);
    }
  }, [profile]);

  const onFinish = (values: any) => {
    updateProfile({ birth, ...values });
    setIsVisibleSaveBtn(false);
  };

  const onChangePicker = (value: string) => {
    setBirth(value);
    value !== birth && setIsVisibleSaveBtn(true);
  };

  return (
    <PageTransitions
      children={(
        <div className="profile">
          <div className="profile__container">
            <div className="profile__container_form">
              <ImgCrop rotate shape="round">
                <Upload
                  accept={'image/*'}
                  name="avatar"
                  headers={{ 'content-type': 'multipart/form-data' }}
                  showUploadList={false}
                  customRequest={(file) => filesUpload(file)}
                >
                  <div className="avatar">
                    <SvgIcon name="add-avatar" />
                    <Avatar
                      size="100"
                      name={name || ''}
                      src={DOMAIN + avatar}
                      round
                    />
                  </div>
                </Upload>
              </ImgCrop>
              <Form
                onValuesChange={(values) => onValuesChange(values)}
                form={form}
                layout="vertical"
                className="form"
                onFinish={onFinish}
              >
                <div className="form__items">
                  <Form.Item
                    name="name"
                    label="Имя"
                    rules={[{ required: true, message: 'Поле Имя обязательно для заполнения' }]}
                  >
                    <Input
                      maxLength={32}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="nickname"
                    label="Никнейм"
                    rules={[{ required: true, message: 'Поле Никнейм обязательно для заполнения' }]}
                  >
                    <Input
                      maxLength={32}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item label="День рождения">
                    <DatePicker
                      showToday={false}
                      clearIcon={false}
                      defaultValue={birthDay ? moment(birthDay, 'YYYY/MM/DD') : undefined}
                      onChange={(e, value) => onChangePicker(value)}
                    />
                  </Form.Item>
                  <Presence
                    children={(<Button type="primary" htmlType="submit" size="large">Сохранить</Button>)}
                    bool={isVisibleSaveBtn}
                    position="center"
                  />
                </div>
              </Form>
            </div>
            <div className="profile__container_logout">
              <Presence
                bool={!confirmExit}
                position="center"
                children={<Button onClick={() => setConfirmExit(true)}>Выйти из аккаунта</Button>}
              />
              <Presence
                bool={confirmExit}
                position="center"
                exitDuration={0}
                children={(
                  <div className="confirm">
                    <Button onClick={() => setConfirmExit(false)}>Отмена</Button>
                    <Button onClick={signOut}>Выйти</Button>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
}

export default Index;
