import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
// pass the i18n instance to react-i18next.
  .use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          myWorkTitle: 'My work',
          personalTitle: 'Personal',
          supportTitle: 'Support',
          dashboard: 'Dashboard',
          landings: 'Landings',
          projects: 'Projects',
          builder: 'Site Builder',
          domains: 'Domains',
          apps: 'Apps',
          profile: 'Profile',
          billing: 'Billing',
          referrals: 'Referrals',
          help: 'Help',
          support: 'Support',
          manager: 'Manager',
          changelog: 'Changelog',

          uploadButtonText: 'Upload Landing',

          loginHeader: 'Sign in to Expresslanding',
          createAccountQ: 'New Here?',
          hasAccountQ: 'Already have an account?',
          createAccountLinkText: 'Create an Account',
          loginAccountLinkText: 'Log In',
          submitLoginText: 'Continue',
          emailLabel: 'Email',
          passLabel: 'Password',
          passConfirmLabel: 'Repeat Password',
          forgotPassText: 'Forgot password?',
          landingCreateLabel: 'Landing Name',
          landingDetailsLabel: 'Landing Details',
          landingDomainLabel: 'Landing Domain',
          landingProjectLabel: 'Landing Project',
          landingCreatePlaceholder: 'Enter Landing Name',
          landingDetailsPlaceholder: 'Type Landing Details',
          projectCreateLabel: 'Project Name',
          projectDetailsLabel: 'Project Details',
          projectCreatePlaceholder: 'Enter Project Name',
          projectDetailsPlaceholder: 'Type Project Details',
          categoryLabel: 'Category',
          domainsLabel: 'Domains',
          projectLabel: 'Project',

          createLandingHeader: 'Your Landing on Express Landing',
          createLandingSubHeader: 'You are one step away from quickly publishing your landing page',
          createProjectHeader: 'Your Project on Express Landing',

          signOutTitle: 'Sign Out',

          welcomeText: 'Welcome to Express Landing',
          createLandingSubText1: 'The fastest way to get your landing page up and running!',
          createLandingSubText2: 'Your site will be accessible with minimal delay from anywhere in the world. More speed, more new leeds!',

          createFirstLandingButtonText: 'Create Your First Landing',

          comingSoonText: 'Coming Soon',

          requiredInputText: 'Required',

          landing: 'Landing',
          source: 'Source',
          landingTabOverview: 'Overview',
          landingTabDomains: 'Domains',
          landingTabSource: 'Source',
          landingTabHandlerSettings: 'Handler settings',
          addDraftVersionLandingBtnTxt: 'Add New Draft Version of Landing',
          publishDraftVersionLandingBtnTxt: 'Publish new version of Landing?',
          publishLandingArchive: 'Upload new draft version of Landing',

          projectTabOverview: 'Overview',
          projectTabLandings: 'Landings',

          chooseSelectCategoryPlaceholder: 'Choose more categories',
          chooseSelectDomainPlaceholder: 'Choose more domains',
          chooseSelectProjectPlaceholder: 'Choose project',

          ContinueBtnTitle: 'Continue',
          createLandingBtnTitle: 'Create Landing',

          approvedTagText: 'Approved',
          notApprovedTagText: 'Not Approved',
          activeTagText: 'Active',

          addProjectText: 'Create Project',
          deleteProjectText: 'Delete Project',
          deleteLandingText: 'Delete Landing',
          editProjectText: 'Update Project',
          editLandingText: 'Update Landing',
          searchLandingText: 'Search Landing',
          successUpdatedProjectText: 'Project updated successfully',
          successDeletedProjectText: 'Project deleted successfully',
          confirmDeletedProjectText: 'Are you sure you want to delete',

          formHandlersLabel: 'Form Handling Settings',
          createFormHandlerText: 'Create Form Handler',
          postbacksLabel: 'Postbacks Settings',
          createPostbackText: 'Create Postback',
          addFormHandlerBtnText: 'Add new Form Handler',
          addPostbackBtnText: 'Add new Postback',
          formHandlersSaveBtnText: 'Create New Form Handler',
          postbackSaveBtnText: 'Create New Postback',

          formHandlerNameLabel: 'Form Handler Name',
          formHandlerNamePlaceholder: 'Enter Form Handler Name',
          formHandlerActionLabel: 'Form Handler Action',
          formHandlerPathLabel: 'Redirect Path',
          formHandlerPathPlaceholder: 'Enter Redirect Path',

          postbackNameLabel: 'Postback Name',
          postbackNamePlaceholder: 'Enter Postback Name',
          postbackRequestUrlLabel: 'Postback Request URL',
          postbackRequestUrlPlaceholder: 'Enter Postback Request URL',
          postbackRequestTypeLabel: 'Postback Request Type',
          postbackRequestTypePlaceholder: 'Enter Postback Request Type',
          postbackAdditionalHeadersLabel: 'Postback Additional Headers',
          postbackAdditionalHeadersPlaceholder: 'Enter Postback Additional Headers',

          postbackHeaderNamePlaceholder: 'Enter Header Name',
          postbackHeaderValuePlaceholder: 'Enter Header Value',

          GetMoreDomains: 'Get more domains',

          uploadFileSuccess: 'file uploaded successfully',
          uploadFileFailed: 'file uploaded failed',
          uploadFilesText: 'Click or drag file to this area to upload',
          uploadFilesSubText: 'Upload you landing zip source',
          versionsTableVersion: 'Version',
          versionsTableStatus: 'Status',
          versionsTableDate: 'Created',
        },
      },
    },
  });

export default i18n;
