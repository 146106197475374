import { ContactsActionsTypes } from './types';
import { ContactsActions, IState } from './models';

const initialState: IState = {
  users: null,
  error: null,
};

export function contactsReducer(state = initialState, action: ContactsActions): IState {
  switch (action.type) {
    case ContactsActionsTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null,
      };
    case ContactsActionsTypes.GET_USERS_FAILED:
      return {
        ...state,
        users: null,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default contactsReducer;
