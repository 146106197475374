import { Dispatch } from 'redux';
import http from '../../../service/axios-custom';
import { API_URL } from '../../../constants';
import { ChatActions } from './models';
import { ChatActionsTypes } from './types';
import { MessageData } from '../../../types';

export const getChats = (page = 1, limit = 100) => async (dispatch:Dispatch<ChatActions>) => {
  try {
    const res = await http.get(`${API_URL}/chats?page=${page}&limit=${limit}`);
    dispatch({ type: ChatActionsTypes.GET_CHATS_SUCCESS, payload: res.data });
  } catch (error: any) {
    dispatch({ type: ChatActionsTypes.GET_CHATS_FAILED, payload: error.response.data.message });
  }
};

export const getChat = (chat_id: number) => async (dispatch: Dispatch<ChatActions>) => {
  if (chat_id) {
    try {
      const res = await http.get(`${API_URL}/chats/${chat_id}`);
      dispatch({ type: ChatActionsTypes.GET_CHAT_SUCCESS, payload: res.data.data });
    } catch (error: any) {
      dispatch({ type: ChatActionsTypes.GET_CHAT_FAILED, payload: error.response.data.message });
    }
  }
};

export const createChat = (data: any) => async (dispatch: Dispatch<ChatActions>) => {
  try {
    const res = await http.post(`${API_URL}/chats`, data);
    dispatch({ type: ChatActionsTypes.CREATE_CHAT_SUCCESS, payload: res.data.data });
    window.location.href = `/chats/${res.data.data.id}`;
  } catch (error: any) {
    dispatch({ type: ChatActionsTypes.CREATE_CHAT_FAILED, payload: error.response.data.message });
  }
};

export const deleteChat = (chat_id: number) => async (dispatch: Dispatch<ChatActions>) => {
  try {
    await http.delete(`${API_URL}/chats/${chat_id}`);
    const res = await http.get(`${API_URL}/chats?page=${1}&limit=${100}`);
    // window.location.href = '/chats';
    dispatch({ type: ChatActionsTypes.DELETE_CHAT_SUCCESS, payload: res.data });
  } catch (error: any) {
    dispatch({ type: ChatActionsTypes.DELETE_CHAT_FAILED, payload: error.response.data.message });
  }
};

export const sendMessage = (chat_id: number, message: MessageData) => async (dispatch:Dispatch<ChatActions>) => {
  try {
    const res = await http.post(`${API_URL}/chats/message/${chat_id}`, message);
    dispatch({ type: ChatActionsTypes.SEND_MESSAGE_SUCCESS, payload: res.data.message });
  } catch (error: any) {
    dispatch({ type: ChatActionsTypes.SEND_MESSAGE_FAILED, payload: error.response.data.message });
  }
};

export const messageFromSocket = (data: any) => async (dispatch:Dispatch<ChatActions>) => {
  dispatch({ type: ChatActionsTypes.GET_SOCKET_MESSAGE, payload: data.message });
};
