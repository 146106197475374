import { ChatsData, DataInsideChats, MessageData } from '../types';
import moment from 'moment';
import { IState } from '../pages/chats/duck/models';
import Dto from '../dto';

class UpdateChats {
  private readonly state;

  private chatIndex: number | null = null;

  constructor(state: IState | null) {
    this.state = state;
  }

  getSortChats(chats: ChatsData):ChatsData {
    chats?.data.forEach((chat:DataInsideChats) => {
      chat.users.length > 2 ? chat.chatType = 'public' : chat.chatType = 'private';
      if (!chat.message[0]) {
        !chat.message.push({
          id: 0,
          message_type: '',
          text: '',
          initiator_id: 0,
          created_at: new Date('1960-08-20T10:51:11.457Z'),
        });
      }
    });
    chats?.data.sort((a, b) => moment(a.message[0]?.created_at).unix() - moment(b.message[0]?.created_at).unix()).reverse();
    return chats;
  }

  getUpdateChats(msg: MessageData): ChatsData | null {
    if (this.state?.chats) {
      const { chats } = this.state;

      const foundChat = chats?.data.find((i, index: number) => {
        this.chatIndex = index;
        if (i.id === Number(msg.chat_id)) {
          return i;
        }
      });
      if (foundChat) {
        foundChat.message[0] = msg;
        if (this.chatIndex) {
          this.state?.chats?.data.splice(this.chatIndex, 1);
          this.state?.chats?.data.unshift(foundChat);
        }
      } else {
        chats?.data.unshift(Dto.chat(msg));
      }
      return chats;
    }
    return null;
  }
}

export default UpdateChats;
