import { ChatData, MessageData } from '../types';
import Dto from '../dto';
import { _getSelfId } from './index';
import { IState } from '../pages/chats/duck/models';

class UpdateMessages {
  private readonly state;

  private readonly selfId;

  constructor(state: IState | null) {
    this.state = state;
    this.selfId = _getSelfId();
  }

  getUpdateStorageMsg(data: ChatData): ChatData {
    const { chatUsers, message } = data || {};
    let initiatorId: number = 0;
    let prevDate: any = null;
    if (chatUsers) {
      data.chatType = chatUsers.length > 2 ? 'public' : 'private';
    }
    if (message?.length) {
      message.reverse().forEach((msg: MessageData, index: number) => {
        const initiator = chatUsers?.find((user) => user.id === msg.initiator_id);
        msg = Dto.msg(msg, initiator);
        msg.isNewDay = false;
        if (initiatorId !== msg.initiator_id) {
          msg.isFirst = true;
          if (message[index - 1]) {
            message[index - 1].isLast = true;
          }
        }
        message[message.length - 1].isLast = true;
        initiatorId = msg.initiator_id;
        prevDate = msg.created_at;
      });
    }
    return data;
  }

  getUpdateMessageFromSocket(msg : MessageData): ChatData | null {
    if (this.state?.chat) {
      const { chat } = this.state;

      const { initiator_id, initiator, chat_id } = msg;
      if (this.selfId !== initiator_id) {
        if (chat && chat.id === Number(chat_id)) {
          const lastMsg: MessageData = chat?.message[chat.message.length - 1];
          if (lastMsg) {
            msg = Dto.msg(msg, initiator);
            msg.isNewDay = false;
            if (lastMsg.initiator_id === initiator_id) {
              lastMsg.isLast = false;
              msg.isLast = true;
            } else {
              msg.isFirst = true;
              msg.isLast = true;
            }
          } else {
            msg = Dto.msg(msg, initiator);
            msg.isFirst = true;
            msg.isLast = true;
          }
          chat.message.push(msg);
        }
      }
      return chat;
    }
    return null;
  }

  updateMyMessage(message: MessageData):ChatData | null {
    if (this.state?.chat) {
      const { chat } = this.state;
      const chatMsg = chat?.message;
      const lastMsg = chatMsg[chatMsg.length - 1];
      message = Dto.msg(message, message.initiator);
      if (lastMsg) {
        if (lastMsg.initiator_id !== message.initiator_id) {
          message.isFirst = true;
          message.isLast = true;
          lastMsg.isLast = true;
        } else {
          lastMsg.isLast = false;
        }
      } else {
        message.isFirst = true;
        message.isLast = true;
      }
      chat?.message.push(message);
      return chat;
    }
    return null;
  }
}

export default UpdateMessages;
