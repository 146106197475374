import { ChatUserData, MessageData } from '../types';
import { _getSelfId } from '../service';

export default (msg: MessageData, initiator: ChatUserData | undefined) => {
  const selfId = _getSelfId();
  msg.userName = initiator?.name || '';
  msg.userAvatar = initiator?.avatar || '';
  msg.isSelf = selfId === initiator?.id;
  return msg;
};
