import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import SliderLine from '../animations/slider-line';
import { useActions } from '../../store/hooks';

import './header.less';

function Menu() {
  const { deleteChat } = useActions();

  const [activeItem, setActiveItem] = useState<number | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const getProfile = () => {

  };

  const handleDeleteChat = () => {
    if (id) {
      deleteChat(Number(id));
      navigate('/chats');
    }
  };

  const items = [
    {
      id: 0, title: 'Профиль', icon: <UserOutlined />, onClick: getProfile,
    },
    {
      id: 1, title: 'Удалить чат', icon: <DeleteOutlined />, onClick: handleDeleteChat,
    },
  ];

  return (
    <div className="header-menu">
      {items.map((item) => (
        <div
          onClick={item.onClick}
          key={item.title}
          className="header-menu__item"
          onMouseEnter={() => setActiveItem(item.id)}
          onMouseLeave={() => setActiveItem(null)}
        >
          <span className="header-menu__item_title">{item.title}</span>
          <span className="header-menu__item_icon">{item.icon}</span>
          {activeItem === item.id && <SliderLine layoutId="headerMnu" color="#7e8299" />}
        </div>
      ))}
    </div>
  );
}

export default Menu;
