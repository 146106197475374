import { DataInsideChats, MessageData } from '../types';
import { _getSelfId } from '../service';

export default (msg: MessageData): DataInsideChats => {
  const selfId = _getSelfId();

  const { created_at, initiator, chat_id } = msg;

  const {
    avatar, nickname, name, id,
  } = initiator || {};

  return {
    id: Number(chat_id),
    created_at,
    avatar: avatar || nickname || name || null,
    name: nickname || name || 'unknown',
    message: [msg],
    users: [id, selfId],
  };
};
