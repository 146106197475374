import { Dispatch } from 'redux';
import http from '../../../service/axios-custom';
import { API_URL } from '../../../constants';
import { ProfileActions } from './models';
import { ProfileActionsTypes } from './types';
import { UserData } from '../../../types';

export const getProfile = () => async (dispatch: Dispatch<ProfileActions>) => {
  try {
    const res = await http.get<any>(`${API_URL}/profile`);
    dispatch({ type: ProfileActionsTypes.GET_PROFILE_SUCCESS, payload: res.data.data });
  } catch (error: any) {
    dispatch({ type: ProfileActionsTypes.GET_PROFILE_FAILED, payload: error.response.data.message });
  }
};

export const updateProfile = (data: any) => async (dispatch: Dispatch<ProfileActions>) => {
  try {
    const res = await http.patch(`${API_URL}/profile`, data);
    dispatch({ type: ProfileActionsTypes.UPDATE_PROFILE_SUCCESS, payload: res.data.data });
  } catch (error: any) {
    dispatch({ type: ProfileActionsTypes.UPDATE_PROFILE_FAILED, payload: error.response.data.message });
  }
};

export const filesUpload = (file: any) => async (dispatch: any) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file.file);
    const res = await http.post(`${API_URL}/files/upload`, bodyFormData);
    const avatar = res.data.data.pathToFile;
    dispatch(updateProfile({ avatar } as UserData));
  } catch (error: any) {
    throw new Error('File not loaded');
  }
};
