import React, { useEffect } from 'react';
import {
  Route, Routes, useLocation, Navigate,
} from 'react-router-dom';
import socket from './service/socket';
import Login from './pages/login';
import Registration from './pages/registration';
import { _isAuthenticated } from './service';
import Profile from './pages/profile';
import Chat from './pages/chats/chat';
import Main from './pages/main';
import Contacts from './pages/contacts';
import Wrapper from './components/wrapper';
import { useActions } from './store/hooks';

function App() {
  const isAuth = _isAuthenticated();
  const location = useLocation();
  const { messageFromSocket } = useActions();

  useEffect(() => {
    socket.on('receiveMessage', (data) => {
      messageFromSocket(data);
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, []);

  return (
    isAuth
      ? (
        <Routes>
          <Route path="*" element={<Navigate to="/chats" replace />} />
          <Route element={<Wrapper />}>
            <Route path="chats" element={<Main />}>
              <Route path=":id" element={<Chat />} />
            </Route>
            <Route path="profile" element={<Profile />} />
            <Route path="contacts" element={<Contacts />} />
          </Route>
        </Routes>
      )
      : (
        <Routes>
          <Route path="*" element={<Navigate to="/registration" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
        </Routes>
      )
  );
}

export default App;
