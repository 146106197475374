export enum ChatActionsTypes {
  GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS',
  GET_CHATS_FAILED = 'GET_CHATS_FAILED',
  GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS',
  GET_CHAT_FAILED = 'GET_CHAT_FAILED',
  CREATE_CHAT_SUCCESS = 'CREATE_CHATS_SUCCESS',
  CREATE_CHAT_FAILED = 'CREATE_CHATS_FAILED',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
  DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS',
  DELETE_CHAT_FAILED = 'DELETE_CHAT_FAILED',
  SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED',
  GET_SOCKET_MESSAGE = 'GET_SOCKET_MESSAGE',
}
