import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface ISliderLine {
  color: string,
  layoutId: string
}

function SliderLine({ color, layoutId }:ISliderLine) {
  return (
    <motion.div
      layoutId={layoutId}
      style={{
        width: '100%',
        height: 4,
        position: 'absolute',
        bottom: -2,
        backgroundColor: color,
        borderRadius: 10,
      }}
    />
  );
}

export default SliderLine;
