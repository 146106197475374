import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface IPresence {
  children: React.ReactNode,
  bool: boolean,
  position: 'left' | 'center' | 'right',
  exitDuration?: number
}

function Presence(props: IPresence) {
  const {
    children, bool, position, exitDuration = 0.3,
  } = props;

  return (
    <AnimatePresence>
      {bool
      && (
      <motion.div
        style={{ display: 'flex', justifyContent: position }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0 } }}
      >
        {children}
      </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Presence;
