import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { MessageData } from '../../../types';
import { useActions } from '../../../store/hooks';
import Presence from '../../../components/animations/presence';
import EmojiPicker from '../../../components/emoji-picker';
import SvgIcon from '../../../components/svg';

interface IInput {
  chatId: number | undefined,
  chatWidth: number | undefined
}

function MsgInput({ chatId, chatWidth }: IInput) {
  const { sendMessage } = useActions();

  const [newMessage, setNewMessage] = useState('');
  const [cols, setCols] = useState<number>(30);
  const onEmojiClick = (event: any, emojiObject: any) => setNewMessage(newMessage + emojiObject.emoji);

  const handleSendMessage = () => {
    if (newMessage && chatId) {
      sendMessage(Number(chatId), {
        message_type: 'text',
        text: newMessage,
      } as MessageData);
      setNewMessage('');
    }
  };

  const handleKeyPress = (e: any) => {
    if (newMessage.split('\n').pop()?.length === cols) {
      setNewMessage(`${newMessage}\n`);
    }
    if (e.code === 'Enter' && (e.ctrlKey || e.altKey)) {
      setNewMessage(`${newMessage}\n`);
    }
    if (e.code === 'Enter' && !e.ctrlKey && !e.altKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const onChange = (value: string) => {
    !value.match(/^[ ]+$/) && setNewMessage(value);
  };

  useEffect(() => {
    chatWidth && setCols(Math.floor((chatWidth - 100) / 12));
  }, [chatWidth]);

  return (
    <div className="msg-input">
      <div className="msg-input__textArea">
        <textarea
          value={newMessage}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e)}
        />
        <Popover placement="topLeft" content={<EmojiPicker onEmojiClick={onEmojiClick} />}>
          <div className="msg-input__textArea_smile">
            <SvgIcon name="smile" />
          </div>
        </Popover>
      </div>
      <div
        className={`msg-input__btn ${newMessage && 'msg-input__btn_active'}`}
        onClick={handleSendMessage}
      >
        <Presence
          bool={!!newMessage}
          children={<SendOutlined />}
          position="center"
        />
      </div>
    </div>
  );
}

export default MsgInput;
