import React, { useState } from 'react';
import ChatsView from '../chats';
import ChatView from '../chats/chat';
import { useTypedSelector } from '../../store/hooks';
import PageTransitions from '../../components/animations/pageTransitions';

import './main.less';

function Index() {
  const [chatId, setChatId] = useState(null);
  const { profile } = useTypedSelector((state) => state.profile);

  return (
    <PageTransitions
      children={(
        <div className="main">
          <div className="main_panel-space">
            <ChatsView />
          </div>
          <div className="main_chat-space">
            <ChatView />
          </div>
        </div>
)}
    />
  );
}

export default Index;
