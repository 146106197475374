import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useActions, useTypedSelector } from '../../store/hooks';
import { DOMAIN } from '../../constants';
import { ChatData, ChatsData, DataInsideChats } from '../../types';

import './chats.less';

function Chats() {
  const { chats } = useTypedSelector((state) => state.chats);

  const { data } = chats || {};

  const { getChats, getChat } = useActions();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    !chats && getChats();
  }, []);

  const selectedChat = (id: number) => {
    getChat(id);
    navigate(`/chats/${id}`);
  };

  return (
    <div className="chats-list">
      {data?.map((chat: ChatData) => chat.name
                    && (
                    <div
                      key={chat.id}
                      className={`chats-list__item ${chat.id === Number(id) && 'chats-list__item_active'}`}
                      onClick={() => selectedChat(chat.id)}
                    >
                      <div className="chats-list__item_avatar">
                        <Avatar
                          size="54"
                          name={chat.name || ''}
                          src={chat.avatar ? DOMAIN + chat.avatar : ''}
                          round
                        />
                      </div>
                      <div className="chats-list__item_caption">
                        <p className="user-name">{chat.name}</p>
                        {chat?.message[0] && <p className="last-message">{chat?.message[0]?.text}</p>}
                      </div>
                    </div>
                    ))}
    </div>
  );
}

export default Chats;
