class Utils {
  getEnding(count: number | undefined, word: string, ending: string[]) {
    if (count) {
      if (count === 1 || (count > 20 && count % 10 === 1)) {
        return `${word}`;
      }
      if (count > 1 && count < 5 || (count > 20 && count % 10 > 1 && count % 10 < 5)) {
        return `${word}${ending[0]}`;
      }
      return `${word}${ending[1]}`;
    }
  }
}

export default new Utils();
