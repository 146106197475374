import React from 'react';
import { Outlet } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Header from '../header';
import BottomMenu from '../bottomMenu';
import './wrapper.less';

function Wrapper() {
  return (
    <div className="wrapper">
      <Header />
      <AnimatePresence>
        <motion.div
          className="wrapper__outlet"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Outlet />
        </motion.div>
      </AnimatePresence>
      <BottomMenu />
    </div>
  );
}

export default Wrapper;
