import { ProfileActionsTypes } from './types';
import { ProfileActions, IState } from './models';

const initialState: IState = {
  profile: null,
  error: null,
};

export function profileReducer(state = initialState, action: ProfileActions): IState {
  switch (action.type) {
    case ProfileActionsTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        error: null,
      };
    case ProfileActionsTypes.GET_PROFILE_FAILED:
      return {
        ...state,
        profile: null,
        error: action.payload,
      };
    case ProfileActionsTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        error: null,
      };
    case ProfileActionsTypes.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        profile: null,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default profileReducer;
