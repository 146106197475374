import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageData } from '../../../types';
import Message from '../components/message';
import { useActions, useTypedSelector } from '../../../store/hooks';
import MsgInput from '../components/msg-input';
import { MsgContainerVar } from '../../../components/animations/variants';

import './chat.less';

function Chat() {
  const { profile } = useTypedSelector((state) => state.profile);
  const { chat } = useTypedSelector((state) => state.chats);

  const { message = [] } = chat || {};

  const { getProfile, getChat } = useActions();

  const { id } = useParams();
  const chatWrapperRef = useRef<null | HTMLDivElement>(null);
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const { width, height } = useWindowSize();

  const [chatWidth, setChatWidth] = useState<number | undefined>(500);

  useEffect(() => {
    !profile && getProfile();
    !chat && getChat(Number(id));
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, [message]);

  useEffect(() => {
    id && bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [message.length]);

  useEffect(() => {
    setChatWidth(chatWrapperRef.current?.offsetWidth);
  }, [width]);

  return (
    <div ref={chatWrapperRef} className="chat">
      <AnimatePresence>
        <motion.div className="chat__messages" animate="animate" variants={MsgContainerVar} initial="initial" exit="exit">
          {message?.map((message: MessageData) => (
            <div
              key={message.id}
              className="chat__messages_message"
            >
              <Message message={message} />
            </div>
          ))}
          <div ref={bottomRef} />
        </motion.div>
      </AnimatePresence>
      {id && <MsgInput chatId={Number(id)} chatWidth={chatWidth} />}
    </div>
  );
}

export default Chat;
