import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Input } from 'antd';
import SvgIcon from '../../components/svg';
import { saveTokenToCookie } from '../../service';
import { useActions, useTypedSelector } from '../../store/hooks';
import { ERROR_TIME } from '../../constants';
import { IErrors } from '../../types';
import validator from '../../service/validator';

import './login.less';

function Login() {
  const { access_token, error: serverError } = useTypedSelector((state) => state.reg);

  const { login } = useActions();

  const [errors, setErrors] = useState<IErrors[] | null>(null);

  const navigate = useNavigate();

  const onFinish = ({ phone, code }: { phone: string, code: string }) => {
    const { isSuccess, errors } = validator({ phone, code });
    isSuccess ? login({ phone: `+7${phone}`, code }) : setErrors(errors);
    setTimeout(() => setErrors(null), ERROR_TIME);
  };

  useEffect(() => {
    if (serverError) setErrors([{ cause: 'unknown', message: 'Неверный номер или код' }]);
    if (access_token) {
      saveTokenToCookie(access_token);
      navigate('/chats');
    }
  }, [serverError, access_token]);

  return (
    <div className="login">
      <SvgIcon name="logo" />
      <Form
        className="login__form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          className="login__form_phone"
          validateStatus={errors?.find((error) => error.cause === 'phone' || error.cause === 'unknown') ? 'error' : ''}
          help={errors?.find((error) => error.cause === 'phone')?.message}
          name="phone"
          label="Номер телефона"
        >
          <Input
            prefix="+7"
            size="large"
            maxLength={10}
          />
        </Form.Item>
        <Form.Item
          className="login__form_code"
          validateStatus={errors?.find((error) => error.cause === 'code' || error.cause === 'unknown') ? 'error' : ''}
          help={errors?.find((error) => error.cause === 'code' || error.cause === 'unknown')?.message}
          name="code"
          label="Код"
        >
          <Input
            size="large"
          />
        </Form.Item>
        <div className="login__form_bottom">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
          >
            Войти
          </Button>
          <Link to="/registration">
            Получить код
          </Link>
        </div>
      </Form>
    </div>
  );
}

export default Login;
