import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { MessageOutlined, UserOutlined, ContactsOutlined } from '@ant-design/icons';
import SliderLine from '../animations/slider-line';
import { useLocalStorage } from 'react-use';

import './bottomMenu.less';

function BottomMenu() {
  const location = useLocation();
  const { id } = useParams();
  const [value, setValue, remove] = useLocalStorage('lastActiveChat');

  const items = [
    { path: '/profile', icon: <UserOutlined /> },
    { path: `/chats/${value || ''}`, icon: <MessageOutlined /> },
    { path: '/contacts', icon: <ContactsOutlined /> },
  ];

  const onClick = () => {
  };

  useEffect(() => {
    id && setValue(id);
  }, [id]);

  return (
    <div className="bottom-menu">
      {items.map((item) => (
        <div
          key={item.path}
          className="bottom-menu__item"
          onClick={onClick}
        >
          <Link to={item.path}>
            {item.icon}
          </Link>
          {location.pathname === item.path && <SliderLine layoutId="btmMenu" color="#7e8299" />}
        </div>
      ))}
    </div>
  );
}

export default BottomMenu;
