import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ERROR_TIME } from '../../constants';
import { Form, Button, Input } from 'antd';
import SvgIcon from '../../components/svg';
import { useActions, useTypedSelector } from '../../store/hooks';
import validator from '../../service/validator';
import { IErrors } from '../../types';

import './registration.less';

function Registration() {
  const { code_success, error: serverError } = useTypedSelector((state) => state.reg);

  const { sendCode } = useActions();

  const [errors, setErrors] = useState<IErrors[] | null>(null);

  const onFinish = ({ phone }: { phone: string }) => {
    const { isSuccess, errors } = validator({ phone });
    isSuccess ? sendCode(`+7${phone}`) : setErrors(errors);
    setTimeout(() => setErrors(null), ERROR_TIME);
  };

  useEffect(() => {
    if (serverError) setErrors([{ cause: 'phone', message: 'Номер недействителен' }]);
    if (code_success) window.location.href = '/login';
  }, [serverError, code_success]);

  return (
    <div className="registration">
      <SvgIcon name="logo" />
      <Form
        className="registration__form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          className="registration__form_phone"
          validateStatus={errors?.length ? 'error' : ''}
          help={errors?.find((error) => error.cause === 'phone')?.message}
          name="phone"
          label="Номер телефона"
        >
          <Input
            prefix="+7"
            size="large"
            maxLength={10}
          />
        </Form.Item>
        <div className="registration__form_bottom">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
          >
            Отправить код
          </Button>
          <Link to="/login">Войти с кодом</Link>
        </div>
      </Form>
    </div>
  );
}

export default Registration;
