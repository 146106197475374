import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from 'react-avatar';
import { DOMAIN } from '../../constants';
import SvgIcon from '../../components/svg';
import { ChatData, IStringIndex, UserData } from '../../types';
import { useActions, useTypedSelector } from '../../store/hooks';
import PageTransitions from '../../components/animations/pageTransitions';

import './contacts.less';

function ContactsView() {
  const { users, error: contactsError }: IStringIndex = useTypedSelector((state) => state.contacts);
  const { chats, error: chatsError }: IStringIndex = useTypedSelector((state) => state.chats);

  const {
    createChat, getUsers, getChats, getChat,
  } = useActions();

  const navigate = useNavigate();

  useEffect(() => {
    !users && getUsers();
    !chats && getChats();
  }, []);

  const handleCreateChat = (id: number | null) => {
    const privateChats = chats?.data.filter((chat: ChatData) => chat.chatType === 'private');
    const chat = privateChats?.find((chat: ChatData) => chat.users.includes(id || 0));
    if (!chat) {
      createChat({
        name: 'Чат',
        users: [id],
      });
    } else {
      getChat(chat.id);
      navigate(`/chats/${chat.id}`);
    }
  };

  return (
    <PageTransitions
      children={(
        <div className="users-list">
          {users?.map((user: UserData) => (user.nickname || user.name)
              && (
              <div
                key={user.id}
                className="users-list__item"
              >
                <Avatar size="50" name={user.nickname || user.name || ''} src={user.avatar ? DOMAIN + user.avatar : ''} round />
                <div className="users-list__item_caption">
                  <p className="nickName">{user.nickname}</p>
                  <p style={{ fontSize: user.nickname ? 12 : 16 }} className="name">{user.name}</p>
                </div>
                <div
                  className="users-list__item_message"
                  onClick={() => handleCreateChat(user.id)}
                >
                  <SvgIcon name="message" />
                </div>
              </div>
              ))}
        </div>
      )}
    />
  );
}

export default ContactsView;
