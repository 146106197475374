import React from 'react';
import Picker from 'emoji-picker-react';

function EmojiPicker({ onEmojiClick }: any) {
  return (
    <Picker
      onEmojiClick={onEmojiClick}
      disableSkinTonePicker
      disableSearchBar
    />
  );
}

export default EmojiPicker;
