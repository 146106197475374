import React from 'react';
import Avatar from 'react-avatar';
import moment from 'moment';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageData } from '../../../types';
import SvgIcon from '../../../components/svg';
import { MsgrVar } from '../../../components/animations/variants';

interface IMessageProps {
  message: MessageData | null,
}

function Message({ message }: IMessageProps) {
  const {
    id, text, initiator_id, created_at, userName, userAvatar, message_type, isSelf, isNewDay, isFirst, isLast,
  } = message || {};

  return (
    <div className={isSelf ? 'self' : 'received'}>
      <div className="avatar">
        {isLast && !isSelf
            && <Avatar size="40" src={userAvatar || ''} name={userName || ''} round />}
      </div>
      <div className={`caption ${isLast ? 'caption_tail' : 'caption_no-tail'}`}>
        <SvgIcon name="tail-msg" />
        {isFirst && !isSelf
              && <p className="caption_name">{userName}</p>}
        <p className="caption_text">{text}</p>
        <div className="caption_date">{moment(created_at).format('HH:mm')}</div>
      </div>
    </div>
  );
}

export default Message;
