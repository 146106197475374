import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL } from '../constants';
import {
  getTokensFromCookie, removeTokensFromCookies, saveRefreshTokenToCookie, saveTokenToCookie,
} from './index';

const cookies = new Cookies();
const http = axios.create();

http.interceptors.response.use(undefined, async (error: any) => {
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    const tokens = getTokensFromCookie();
    const originalRequest = error.config;
    originalRequest._isRetry = true;
    try {
      const res = await axios.post(`${API_URL}/authorization/refresh`, tokens);
      const { access_token, refresh_token } = res.data.data;
      if (access_token && refresh_token) {
        saveTokenToCookie(access_token);
        saveRefreshTokenToCookie(refresh_token);
        console.clear();
      } else {
        removeTokensFromCookies();
      }
      return await http.request(originalRequest);
    } catch (err: any) {
      removeTokensFromCookies();
      throw new Error('Unauthorized');
    }
  }
  throw error;
});

type typeOptions = {
  path: string,
  domain: string
};

const options:typeOptions = {
  path: '/',
  domain: window.location.hostname,
};

/* Подставляет Bearer Token в каждый запрос */
http.interceptors.request.use((config:any) => {
  // @ts-ignore
  const token = cookies.get('access_token', options);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = 'application/json';
  }
  return config;
}, undefined);

export default http;
