import { ChatActionsTypes } from './types';
import { ChatActions, IState } from './models';
import UpdateMessages from '../../../service/update-messages';
import UpdateChats from '../../../service/update-chats';

const initialState:IState = {
  chats: null,
  chat: null,
  message: null,
  messages: null,
  error: null,
};

export function chatsReducer(state = initialState, action: ChatActions): IState {
  const updateChats = new UpdateChats(state);
  const updateMessages = new UpdateMessages(state);

  switch (action.type) {
    case ChatActionsTypes.GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: updateChats.getSortChats(action.payload),
        error: null,
      };
    case ChatActionsTypes.GET_CHATS_FAILED:
      return {
        ...state,
        chats: null,
        error: action.payload,
      };
    case ChatActionsTypes.GET_CHAT_SUCCESS:
      return {
        ...state,
        chat: updateMessages.getUpdateStorageMsg(action.payload),
        error: null,
      };
    case ChatActionsTypes.GET_CHAT_FAILED:
      return {
        ...state,
        chat: null,
        error: action.payload,
      };
    case ChatActionsTypes.CREATE_CHAT_SUCCESS:
      return {
        ...state,
        chat: action.payload,
        error: null,
      };
    case ChatActionsTypes.CREATE_CHAT_FAILED:
      return {
        ...state,
        chat: null,
        error: action.payload,
      };
    case ChatActionsTypes.DELETE_CHAT_SUCCESS:
      return {
        ...state,
        chats: updateChats.getSortChats(action.payload),
        chat: null,
      };
    case ChatActionsTypes.DELETE_CHAT_FAILED:
      return {
        ...state,
      };
    case ChatActionsTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.payload,
        chat: updateMessages.updateMyMessage(action.payload),
        error: null,
      };
    case ChatActionsTypes.SEND_MESSAGE_FAILED:
      return {
        ...state,
        message: null,
        error: action.payload,
      };
    case ChatActionsTypes.GET_SOCKET_MESSAGE:
      return {
        ...state,
        chat: updateMessages.getUpdateMessageFromSocket(action.payload),
        chats: updateChats.getUpdateChats(action.payload),
        error: null,
      };
    default:
      return state;
  }
}

export default chatsReducer;
