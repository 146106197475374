import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/ru_RU';
import 'moment/locale/ru';
import moment from 'moment';
import App from './App';
import store from './store';
import './i18n';
import './styles/index.less';
import './styles/responsive.less';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

moment.locale('ru');

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider locale={locale}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
);
