import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import regReducer from '../pages/registration/duck/reducer';
import profileReducer from '../pages/profile/duck/reducer';
import chatsReducer from '../pages/chats/duck/reducer';
import contactsReducer from '../pages/contacts/duck/reducer';

const rootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  reg: regReducer,
  profile: profileReducer,
  chats: chatsReducer,
  contacts: contactsReducer,
});

export default rootReducer;
